// @flow
import Modal from "./wf-modal"
import Button from "./wf-button"
import MyReportsModal from "./wf-my-reports-modal"
import TextInput from "./form_elements/wf-text-input"
import TextArea from "./form_elements/wf-textarea"

if (!customElements.get("wf-modal")) {
  customElements.define("wf-modal", Modal)
}

if (!customElements.get("wf-button")) {
  customElements.define("wf-button", Button)
}

if (!customElements.get("wf-text-input")) {
  customElements.define("wf-text-input", TextInput)
}

if (!customElements.get("wf-textarea")) {
  customElements.define("wf-textarea", TextArea)
}

if (!customElements.get("wf-my-reports-modal")) {
  customElements.define("wf-my-reports-modal", MyReportsModal)
}
