/* eslint-disable flowtype/require-valid-file-annotation */

import I18n from "helpers/i18n"

const template = /* html */ `
  <wf-modal heading="${I18n.t("js.reports.my_reports.save")}" type="confirm" backdropClose="false">
    <span slot="trigger">
      <wf-button text="${I18n.t("js.reports.my_reports.save")}"></wf-button>
    </span>
    <div slot="content" class="content">
      <p>
        ${I18n.t("js.reports.my_reports.explanation")}
      </p>
      <form>
        <wf-text-input label="${I18n.t("js.reports.my_reports.name")}" id="report-name"></wf-text-input>
        <wf-textarea label="${I18n.t("js.reports.my_reports.description")}" id="report-description"></wf-textarea>
      </form>
    </div>
    <span slot="close-btn">
      <wf-button type="tertiary" text="${I18n.t("js.reports.my_reports.close")}" class="close-btn"></wf-button>
    </span>
    <span slot="primary-btn">
      <wf-button text="${I18n.t("js.reports.my_reports.save")}"></wf-button>
    </span>
  </wf-modal>
  <style>
    * {
      box-sizing: border-box;
      font-family: "Lato";
      webkit-font-smoothing: antialiased;
    }
    p {
      line-height: 1.5;
      color: #3c4754;
    }
    .content {
      text-align: left;
      font-size: 16px;
    }
    input, textarea {
      display: block;
      width: 100%;
    }
    h2 {
      color: #3c4754;
    }
    form {
      margin-top: 24px;
    }

    wf-text-input + wf-textarea {
      margin-top: 16px;
    }
  </style>
`

export default class MyReportsModal extends HTMLElement {
  constructor() {
    super()
    this.attachShadow({ mode: "open" })
    this.shadowRoot.innerHTML = template
  }
}
/* eslint-enable flowtype/require-valid-file-annotation */
