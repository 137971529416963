/* eslint-disable flowtype/require-valid-file-annotation */

const template = (options) => /* html */ `
  <label for="${options.id}">${options.label}</label>
  <textarea rows="4" id="${options.id}">${options.value}</textarea>
  <style>
    :host {
      display: block;
    }

    * {
      box-sizing: border-box;
      font-family: "Lato";
      webkit-font-smoothing: antialiased;
    }

    label, textarea {
      display: block;
    }

    label {
      color: #3c4754;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      display: block;
      margin-bottom: 2px;
    }

    textarea {
      border: 1px solid #cad2de;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      padding: 16px;
      width: 100%;
      color: #3c4754;
      outline: none;
      line-height: 1.25;
    }

    textarea:focus-visible {
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset, rgba(28, 179, 217, 0.8) 0px 0px 0px 2px inset, rgba(83, 97, 113, 0.2) 0px 1px 2px 0px
    }
  </style>
`

export default class TextArea extends HTMLElement {
  static observedAttributes = ["attributes", "label", "value", "id"]
  constructor() {
    super()
    this.attachShadow({ mode: "open" })
    this.buildHTML()
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this.buildHTML()
  }

  buildHTML() {
    this.shadowRoot.innerHTML = template({
      label: this.label,
      value: this.value,
      id: this.id,
    })
    this.setAttributes()
  }

  get input() {
    return this.shadowRoot.querySelector("input")
  }

  get id() {
    return this.getAttribute("id") || ""
  }

  get label() {
    return this.getAttribute("label") || ""
  }

  get value() {
    return this.getAttribute("value") || ""
  }

  get attributes() {
    return JSON.parse(this.getAttribute("attributes")) || {}
  }

  setAttributes() {
    Object.entries(this.attributes).forEach(([key, value]) => {
      this.input.setAttribute(key, value)
    })
  }
}

/* eslint-enable flowtype/require-valid-file-annotation */
