/* eslint-disable flowtype/require-valid-file-annotation */

const template = (options) => /* html */ `
  <button class="btn btn-${options.type}">${options.text}</button>
  <style>
    * {
      box-sizing: border-box;
      font-family: "Lato";
      webkit-font-smoothing: antialiased;
    }

    .btn {
      font-size: 13px;
      border: 0;
      text-transform: uppercase;
      font-weight: 600;
      padding: 4px 12px;
      border-radius: 6px;
      display: inline-flex;
      cursor: pointer;
      font-family: "Lato";
      line-height: 22.764px;
      letter-spacing: 0.3252px;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      white-space: nowrap;
      -webkit-font-smoothing: antialiased;
    }

    .btn:active {
      transform: scale(0.95);
    }

    .btn-default {
      color: white;
      background-image: linear-gradient(to right bottom, rgb(28, 179, 217), rgb(25, 127, 166));
    }

    .btn-default:hover {
      background-image: linear-gradient(to right bottom, rgb(28, 179, 217), rgb(23, 92, 120))
    }

    .btn-tertiary {
      color: #197fa6;
      background-color: white;
    }

    .btn-tertiary:hover {
      background-image: linear-gradient(to right bottom, rgb(255, 255, 255), rgb(240, 242, 245))
    }
  </style>
`

export default class BtnDefault extends HTMLElement {
  static observedAttributes = ["attributes", "text"]
  constructor() {
    super()
    this.attachShadow({ mode: "open" })
    this.buildHTML()
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this.buildHTML()
  }

  buildHTML() {
    this.shadowRoot.innerHTML = template({ text: this.text, type: this.type })
    this.setAttributes()
  }

  get button() {
    return this.shadowRoot.querySelector("button")
  }

  get type() {
    return this.getAttribute("type") || "default"
  }

  get text() {
    return this.getAttribute("text") || ""
  }

  get attributes() {
    return JSON.parse(this.getAttribute("attributes")) || {}
  }

  setAttributes() {
    Object.entries(this.attributes).forEach(([key, value]) => {
      this.button.setAttribute(key, value)
    })
  }
}

/* eslint-enable flowtype/require-valid-file-annotation */
